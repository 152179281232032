<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Determine which of the following combinations of quantum numbers represent a valid set.
        Select all valid answers.
      </p>

      <v-simple-table style="max-width: 400px">
        <thead>
          <tr>
            <th style="font-size: 14px" class="mb-0 mt-0">
              <stemble-latex content="$\text{Valid?}$" />
            </th>
            <th style="font-size: 14px; text-align: center">
              <stemble-latex content="$\text{n}$" />
            </th>
            <th style="font-size: 14px; text-align: center">
              <stemble-latex content="$\text{l}$" />
            </th>
            <th style="font-size: 14px; text-align: center">
              <stemble-latex content="$\text{m}_\ell$" />
            </th>
            <th style="font-size: 14px; text-align: center">
              <stemble-latex content="$\text{m}_\text{s}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(option, i) in options1" :key="option.value" class="text-center">
            <td class="mb-0 mt-0">
              <v-checkbox
                v-model="inputs.input1"
                :value="option.value"
                hide-details="true"
                :ripple="false"
                class="pl-3"
              />
            </td>
            <td class="mb-0 mt-0">
              <number-value :value="ns[i]" />
            </td>
            <td class="mb-0 mt-0">
              <number-value :value="ls[i]" />
            </td>
            <td class="mb-0 mt-0">
              <number-value :value="mls[i]" />
            </td>
            <td class="mb-0 mt-0">
              <latex-number :number="spins[i]" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question19',
  components: {NumberValue, StembleLatex, LatexNumber},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options1: [
        {text: '', value: 'a'},
        {text: '', value: 'b'},
        {text: '', value: 'c'},
        {text: '', value: 'd'},
      ],
    };
  },
  computed: {
    n1() {
      return this.taskState.getValueBySymbol('n1').content;
    },
    n2() {
      return this.taskState.getValueBySymbol('n2').content;
    },
    n3() {
      return this.taskState.getValueBySymbol('n3').content;
    },
    n4() {
      return this.taskState.getValueBySymbol('n4').content;
    },
    l1() {
      return this.taskState.getValueBySymbol('l1').content;
    },
    l2() {
      return this.taskState.getValueBySymbol('l2').content;
    },
    l3() {
      return this.taskState.getValueBySymbol('l3').content;
    },
    l4() {
      return this.taskState.getValueBySymbol('l4').content;
    },
    ml1() {
      return this.taskState.getValueBySymbol('ml1').content;
    },
    ml2() {
      return this.taskState.getValueBySymbol('ml2').content;
    },
    ml3() {
      return this.taskState.getValueBySymbol('ml3').content;
    },
    ml4() {
      return this.taskState.getValueBySymbol('ml4').content;
    },
    ns() {
      return [this.n1, this.n2, this.n3, this.n4];
    },
    ls() {
      return [this.l1, this.l2, this.l3, this.l4];
    },
    mls() {
      return [this.ml1, this.ml2, this.ml3, this.ml4];
    },
    spins() {
      return ['+1/2', '-1/2', '-1/2', '0'];
    },
  },
};
</script>
<style scoped>
.custom-margin {
  margin-bottom: 4px;
}

.custom-td {
  vertical-align: top;
  text-align: center;
  padding-top: 3px;
}

.v-input--selection-controls {
  margin: 0 6px 0 0;
  padding: 0 0 1px 24px;
}
</style>
